import React from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '@context/stateContext'
import { Link } from 'gatsby'
import components from '../../content/common'
import {I18nextContext} from "gatsby-plugin-react-i18next";
import { useLocation } from '@reach/router'

const StyledFooter = styled.div`
  ${tw`h-[174px] lg:h-[260px] flex p-5 flex-col lg:shadow-none bg-white`}
  //pb-260px needed to have footer and sticky bottom visible at the same time 
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.07);
  padding-bottom: ${({ stickyVisible }) => (stickyVisible ? '220px' : '20px')};
  @media (min-width: 375px) {
    padding-bottom: ${({ stickyVisible }) =>
      stickyVisible ? '300px' : '20px'};
  }
  @media (min-width: 480px) {
    padding-bottom: ${({ stickyVisible }) =>
      stickyVisible ? '360px' : '20px'};
  }
  @media (min-width: 640px) {
    padding-bottom: ${({ stickyVisible }) =>
      stickyVisible ? '400px' : '20px'};
  }
  @media screen and (min-width: 1024px) {
    padding-bottom: ${({ stickyVisible }) =>
      stickyVisible ? '340px' : '20px'};
  }

  p {
    ${tw`font-roboto text-15px lg:text-18px text-gray tracking-0.25 cursor-pointer`}
    transition: filter 0.3s ease-in-out;
    transform: translateZ(0);
    &:hover {
      filter: drop-shadow(2px 4px 0.75rem rgba(0, 110, 66, 1));
      transform: translateZ(0);
    }
  }
`
const LeftColumn = styled.div`
  ${tw`flex flex-col justify-between flex-1 lg:flex-row lg:items-start `}
`

const RightColumn = styled.div`
  ${tw`flex flex-col justify-between flex-1 lg:flex-row lg:items-start`}
`

const Bottom = tw.div`flex w-full lg:hidden`

const Top = tw.div`w-full h-1/2 lg:flex hidden relative`

const Logo = styled.div`
  ${tw` absolute left-[50%] top-[31px] lg:top-[100%]`}
  transform: translate(-50%, -50%);
`
const StyledDesktopBottom = styled.div`
  ${tw`hidden w-full py-5 lg:flex h-1/2 `}
`

const DesktopLeft = tw.div`flex flex-1`

const DesktopRight = tw.div`flex flex-1 justify-end`

const LogosWrap = styled.div`
  ${tw`flex pt-2.5 lg:pt-0 justify-start`}

  a {
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(0, 110, 66, 1));
    }
  }
`
const MobileBottom = ({ activeScreen, footer, language, pathname }) => {
  const disabledLinks = (['/', '/en/'].includes(pathname) && activeScreen !== 3);
  if (language === 'de') {
    return (
      <Bottom>
        <LeftColumn>
          <div>
            {/*
            <Link
              to="/handelspartner/"
              className={`${disabledLinks && 'pointer-events-none'}`}
            >
              <p>{footer.handelspartner}</p>
            </Link>
          */}
            <Link
              to="/events/"
              className={`${disabledLinks && 'pointer-events-none'}`}
            >
              <p>{footer.events}</p>
            </Link>
            <p>
              <Link to="/">DE</Link> | <Link to="/en">EN</Link>
            </p>
          </div>
          <Link to="/">
            <img
              src={footer.mobileLogo.default}
              alt={footer.logoText}
              tw="max-w-max max-h-[176px]"
            />
          </Link>
        </LeftColumn>
        <RightColumn>
          <div tw="max-w-max">
            <div>
                <a
                  href="https://de.erdinger.de/brauerei/kontakt.html"
                  target="_blank"
                >
                  <p>{footer.kontakt}</p>
                </a>
                <Link
                  to="/presse/"
                  className={`${disabledLinks && 'pointer-events-none'}`}
                >
                  <p>{footer.presse}</p>
                </Link>
                <Link to="/impressum/"><p>{footer.impresum}</p></Link>
                <Link to="/datenschutz/"><p>{footer.datenschutz}</p></Link>
            </div>
            <LogosWrap>
              <a href={footer.igLink} target="blank" tw="mr-4">
                <img src={footer.ig.default} alt={footer.igText} tw="h-5"></img>
              </a>
            </LogosWrap>
          </div>
        </RightColumn>
      </Bottom>
    )
  } else {
    return (
      <Bottom>
        <LeftColumn>
          <div>
            <Link
              to="/en/#radler"
              className={`${disabledLinks && 'pointer-events-none'}`}
            >
              <p>{footer.radler}</p>
            </Link>
            <Link
              to="/en/#helles"
              className={`${disabledLinks && 'pointer-events-none'}`}
            >
              <p>{footer.helles}</p>
            </Link>
            <p>
              <Link to="/">DE</Link> | <Link to="/en">EN</Link>
            </p>
          </div>
          <Link to="/en/">
            <img
              src={footer.mobileLogo.default}
              alt={footer.logoText}
              tw="max-w-max max-h-[176px]"
            />
          </Link>
        </LeftColumn>
        <RightColumn>
          <div tw="max-w-max">
            <div>
              <a
                href="https://int.erdinger.de/brewery/contact.html"
                target="_blank"
              >
                <p>{footer.kontakt}</p>
              </a>
              <Link to="/en/legal-notice/"><p>{footer.impresum}</p></Link>
              <Link to="/en/dataprotectionterms/"><p>{footer.datenschutz}</p></Link>
            </div>
            <LogosWrap>
              <a href={footer.igLink} target="blank" tw="mr-4">
                <img src={footer.ig.default} alt={footer.igText} tw="h-5"></img>
              </a>
            </LogosWrap>
          </div>
        </RightColumn>
      </Bottom>
    )
  }
}

const DesktopBottom = ({ activeScreen, footer, language, pathname }) => {
  const disabledLinks = (['/', '/en/'].includes(pathname) && activeScreen !== 3);
  if (language === 'de') {
    return (
      <StyledDesktopBottom>
        <DesktopLeft>
          <div tw="pr-[100px]">
            {/*
            <Link
              to="/handelspartner/"
              className={`${disabledLinks && 'pointer-events-none'}`}
            >
              <p>{footer.handelspartner}</p>
            </Link>
            */}
            <Link
              to="/events/"
              className={`${disabledLinks && 'pointer-events-none'}`}
            >
              <p>{footer.events}</p>
            </Link>
          </div>
          <div>
            <a
              href="https://de.erdinger.de/brauerei/kontakt.html"
              target="_blank"
            >
              <p>{footer.kontakt}</p>
            </a>
            <Link
              to="/presse/"
              className={`${disabledLinks && 'pointer-events-none'}`}
            >
              <p>{footer.presse}</p>
            </Link>
          </div>
        </DesktopLeft>
        <DesktopRight>
          <div tw="pr-[100px]">
            <Link to='/'><p>DE</p></Link>
            <Link to='/en'><p>EN</p></Link>
          </div>
          <div tw="pr-[100px]">
            <Link to="/impressum/"><p>{footer.impresum}</p></Link>
            <Link to="/datenschutz/"><p>{footer.datenschutz}</p></Link>
          </div>
          <LogosWrap>
            <a href={footer.igLink} target="blank">
              <img src={footer.ig.default} alt={footer.igText} tw="h-5"></img>
            </a>
          </LogosWrap>
        </DesktopRight>
      </StyledDesktopBottom>
    )
  } else {
    return (
      <StyledDesktopBottom>
        <DesktopLeft>
          <div tw="pr-[100px]">
            <Link
              to="/en/#radler"
              className={`${disabledLinks && 'pointer-events-none'}`}
            >
              <p>{footer.radler}</p>
            </Link>
            <Link
              to="/en/#helles"
              className={`${disabledLinks && 'pointer-events-none'}`}
            >
              <p>{footer.helles}</p>
            </Link>
          </div>
          <div>
            <a
              href="https://int.erdinger.de/brewery/contact.html"
              target="_blank"
            >
              <p>{footer.kontakt}</p>
            </a>
          </div>
        </DesktopLeft>
        <DesktopRight>
          <div tw="pr-[100px]">
            <Link to='/'><p>DE</p></Link>
            <Link to='/en'><p>EN</p></Link>
          </div>
          <div tw="pr-[100px]">
            <Link to="/en/legal-notice/"><p>{footer.impresum}</p></Link>
            <Link to="/en/dataprotectionterms/"><p>{footer.datenschutz}</p></Link>
          </div>
          <LogosWrap>
            <a href={footer.igLink} target="blank">
              <img src={footer.ig.default} alt={footer.igText} tw="h-5"></img>
            </a>
          </LogosWrap>
        </DesktopRight>
      </StyledDesktopBottom>
    )
  }
}

const Footer = ({ stickyVisible }) => {
  const { activeScreen } = useStateContext()
  const { footer } = components
  const { language } = React.useContext(I18nextContext)
  const { pathname } = useLocation()

  return (
    <StyledFooter stickyVisible={stickyVisible && activeScreen === 3}>
      <Top>
        <div tw="w-[50%] mr-[118px] border-b-[1px] lg:border-b-green" />
        <div tw="w-[50%] ml-[118px] border-b-[1px] lg:border-b-green" />
        <Logo>
          <Link to="/">
            <img
              src={footer[language || 'de'].logo.default}
              alt={footer[language || 'de'].logoText}
              tw="hidden lg:block max-h-[260px] cursor-pointer"
            />
          </Link>
        </Logo>
      </Top>
      <MobileBottom activeScreen={activeScreen} footer={footer[language || 'de']} language={language} pathname={pathname} />
      <DesktopBottom activeScreen={activeScreen} footer={footer[language || 'de']} language={language} pathname={pathname} />
    </StyledFooter>
  )
}

export default Footer
